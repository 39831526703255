import { useMemo } from "react";
import { createStore } from "redux";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import defaultState from "./defaultState";
import reducer from "./reducer";

const persistConfig = {
  key: 'primary',
  storage,
  whitelist: ['lang', 'mode'], // place to select which state you want to persist
};

const usePersistStore = (initialState = defaultState) => {
  return useMemo(() => {
    const persistedReducer = persistReducer(persistConfig, reducer);
    const store = createStore(
      persistedReducer,
      initialState
    );
    return {
      store,
      persistor: persistStore(store)
    };
  }, [initialState]);
};

export default usePersistStore;