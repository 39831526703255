import { createGlobalStyle } from "styled-components";
import useNoScroll from "../hooks/useNoScroll";

const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    background: ${props => props.theme.color.bg};
    color: ${props => props.theme.color.text};
    font-family: ${props => props.theme.font.default};
    overflow: ${props => props.noScroll ? 'hidden' : 'auto'};
    font-size: ${props => props.theme.dim.fontSize}px;
    ${props => props.theme.isDesktop && `-webkit-tap-highlight-color: transparent;`}
    #root {
      min-height: 100%;
    }
    button {
      background-color: ${props => props.theme.color.primary};
      font-family: ${props => props.theme.font.default};
      border: none;
      padding: ${props => props.theme.dim.btnPadding}px;
      font-size: ${props => props.theme.dim.fontSize}px;
      cursor: pointer;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      :hover {
        background: ${props => props.theme.color.hoverBtnBg};
        color: ${props => props.theme.color.hoverBtnTxt};
      }
      height: fit-content;
      color: ${props => props.theme.color.btnText};
    }
    div {
      transition: .2s width;
      white-space: pre-wrap;
    }
    form {
      display: grid;
      grid-gap: ${props => props.theme.dim.padding}px;
    }
    fieldset {
      display: grid;
      grid-gap: ${props => props.theme.dim.padding}px;
      border: 0;
      padding: 0;
      margin: 0;
    }
    label {
      padding: 0 ${props => props.theme.dim.padding}px;
    }
    input {
      padding: ${props => props.theme.dim.padding}px;
      border-radius: ${props => props.theme.dim.padding}px;
      border: 1px solid;
      border-color: ${props => props.theme.color.border};
      background: ${props => props.theme.color.inputBg};
      color: ${props => props.theme.color.cardText};
    }
    textarea {
      padding: ${props => props.theme.dim.padding}px;
      border-radius: ${props => props.theme.dim.padding}px;
      border: 1px solid;
      border-color: ${props => props.theme.color.border};
      resize: none;
    }
    img {
      display: block;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: ${props => props.theme.dim.padding}px;
    }
    table {
      height: fit-content;
    }
    td {
      vertical-align: top;
    }
    h1 {
      font-weight: normal;
      font-size: ${props => props.theme.dim.h1Size}px;
      padding: 0 ${props => props.theme.dim.padding}px;
      margin: 0;
    }
    h2 {
      font-weight: normal;
      font-size: ${props => props.theme.dim.h2Size}px;
      padding: 0 ${props => props.theme.dim.padding}px;
      margin: 0;
    }
    h3 {
      font-weight: normal;
      font-size: ${props => props.theme.dim.h3Size}px;
      margin: 0;
    }
    a {
      text-decoration: none;
      display: grid;
      color: ${props => props.theme.color.text};
    }
  }
`;

const CustomGlobalStyle = () => {
  const noScroll = useNoScroll();
  return <GlobalStyle noScroll={noScroll} />;
};

export default CustomGlobalStyle;