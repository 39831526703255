import { useMemo } from "react";
import { ThemeProvider } from "styled-components";
import Theme from "../constants/Theme";
import useStore from "../redux/useStore";

const AppThemeProvider = ({ children }) => {
  const { mode, isDesktop } = useStore();
  const theme = useMemo(() => {
    return {
      ...Theme[mode],
      ...Theme.common,
      isDesktop
    };
  }, [mode, isDesktop]);
  return <ThemeProvider theme={theme}>
    {children}
  </ThemeProvider>;
};

export default AppThemeProvider;